import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2536335442/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2536335442/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import awsAmplify_client_44eMaUeCEK from "/codebuild/output/src2536335442/src/src/plugins/awsAmplify.client.ts";
import dayjs_client_A2bzKURDlV from "/codebuild/output/src2536335442/src/src/plugins/dayjs.client.ts";
import googleTagManager_client_Xav7CQJSSD from "/codebuild/output/src2536335442/src/src/plugins/googleTagManager.client.ts";
import mapLoader_client_5PO5z26lsi from "/codebuild/output/src2536335442/src/src/plugins/mapLoader.client.ts";
import msw_client_B2oraOdEuZ from "/codebuild/output/src2536335442/src/src/plugins/msw.client.ts";
import registerGlobalMethods_client_QweWSisidr from "/codebuild/output/src2536335442/src/src/plugins/registerGlobalMethods.client.ts";
import sentry_client_KAXFuL2wum from "/codebuild/output/src2536335442/src/src/plugins/sentry.client.ts";
import vconsole_client_bVGfAYsPrU from "/codebuild/output/src2536335442/src/src/plugins/vconsole.client.ts";
import vee_validate_fgGHHmjH3T from "/codebuild/output/src2536335442/src/src/plugins/vee-validate.ts";
import vueVirtualScroller_client_fF8VyCt7Dv from "/codebuild/output/src2536335442/src/src/plugins/vueVirtualScroller.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  awsAmplify_client_44eMaUeCEK,
  dayjs_client_A2bzKURDlV,
  googleTagManager_client_Xav7CQJSSD,
  mapLoader_client_5PO5z26lsi,
  msw_client_B2oraOdEuZ,
  registerGlobalMethods_client_QweWSisidr,
  sentry_client_KAXFuL2wum,
  vconsole_client_bVGfAYsPrU,
  vee_validate_fgGHHmjH3T,
  vueVirtualScroller_client_fF8VyCt7Dv
]