import type { Card } from '@/features/card/api/types';
import type { Parking } from '@/features/parking/api/types';
import type { SearchType } from '@/features/parking/types';
import type { Coupon } from '@/features/coupon/api/types';
import type { PaymentMethodType } from '@/features/card/constants';
import type { PointUsageValue } from '@/features/yumenavi/constants/point';

type StoredParkingsOnLists = {
  parkings: Parking[];
  searchType: SearchType | undefined;
  page: number;
};

type PaymentMethod = {
  methodType?: PaymentMethodType;
  card?: Card;
  coupon?: Coupon;
  yumenaviPointUsage?: PointUsageValue;
  yumenaviPoints?: number;
};

export const useParkingState = () => {
  /**
   * API取得した駐車場履歴一覧
   * NOTE: どのページ分まで取得できているか判定するためpageも保持
   */
  const storedParkingsOnLists = useState<StoredParkingsOnLists>(
    'storedParkingsOnLists',
    () => ({
      parkings: [],
      searchType: undefined,
      page: 1,
    }),
  );

  /**
   * 検索条件の説明
   * @example この付近,東京都新宿区,...
   */
  const searchDescription = useState<string>('searchDescription', () => '');

  /**
   * 選択された支払い方法
   */
  const paymentMethod = useState<PaymentMethod | undefined>(
    'paymentMethod',
    () => undefined,
  );

  const setStoredParkings = (_parkings: Parking[]) => {
    storedParkingsOnLists.value.parkings = _parkings;
  };

  const setStoredSearchType = (_searchType: SearchType | undefined) => {
    storedParkingsOnLists.value.searchType = _searchType;
  };

  const setStoredPage = (_page: number) => {
    storedParkingsOnLists.value.page = _page;
  };

  const resetStoredParkings = () => {
    storedParkingsOnLists.value.parkings = [];
    storedParkingsOnLists.value.page = 1;
  };

  const setSearchDescription = (_description: string) => {
    searchDescription.value = _description;
  };

  const setPaymentMethod = (_paymentMethod: PaymentMethod | undefined) => {
    paymentMethod.value = _paymentMethod;
  };

  const resetAll = () => {
    resetStoredParkings();
    setStoredSearchType(undefined);
    setSearchDescription('');
    setPaymentMethod(undefined);
  };

  return {
    storedParkingsOnLists: storedParkingsOnLists,
    paymentMethod: readonly(paymentMethod),
    searchDescription: readonly(searchDescription),
    setStoredParkings,
    setStoredSearchType,
    setStoredPage,
    resetStoredParkings,
    setSearchDescription,
    setPaymentMethod,
    resetAll,
  };
};
