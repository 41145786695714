import {
  useNaviparkApiClient as useCustomFetch,
  UseFetchOptions,
} from '@/repositories/client/useNaviparkApiClient';
import {
  YumenaviFlow,
  YumenaviMemberInput,
  YumenaviConnection,
  YumenaviPoint,
} from './types';

const resource = 'yumenavi';

export const YumenaviRepository = () => {
  /**
   * 夢なび同時連携のフローかどうかを取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getIsConcurrentFlow = (options?: UseFetchOptions<YumenaviFlow>) => {
    return useCustomFetch<YumenaviFlow>('GET', `${resource}/flow`, {
      ...options,
    });
  };

  /**
   * 夢なびアカウントの同時作成フローの開始をリクエスト
   * @method POST
   * @param credential {email メールアドレス, password パスワード}
   * @param options useFetchのオプション
   */
  const startConcurrentFlow = (
    credential: { email: string; password: string },
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('POST', `${resource}/flow`, {
      body: { email: credential.email, password: credential.password },
      ...options,
    });
  };

  /**
   * 電話番号の認証コードの送信をリクエスト
   * @method POST
   * @param credential {phoneNumber 電話番号}
   * @param options useFetchのオプション
   */
  const startPhoneNumberAuth = (
    credential: { phoneNumber: string[] },
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('POST', `${resource}/phone`, {
      body: { phoneNumber: credential.phoneNumber },
      ...options,
    });
  };

  /**
   * 電話番号の認証コードの送信
   * @method POST
   * @param credential {phoneNumber 電話番号, code 認証コード}
   * @param options useFetchのオプション
   */
  const sendPhoneNumberAuthCode = (
    credential: { phoneNumber: string[]; code: string },
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('POST', `${resource}/phone/verify`, {
      body: { phoneNumber: credential.phoneNumber, code: credential.code },
      ...options,
    });
  };

  /**
   * 夢なびアカウントを作成する
   * @method POST
   * @param member ユーザーが入力した会員情報
   * @param options useFetchのオプション
   */
  const creteMember = (
    member: YumenaviMemberInput,
    options?: UseFetchOptions<void>,
  ) => {
    return useCustomFetch<void>('POST', `${resource}/member`, {
      body: { ...member },
      ...options,
    });
  };

  /**
   * 夢なびの連携状況を取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getConnection = (options?: UseFetchOptions<YumenaviConnection>) => {
    return useCustomFetch<YumenaviConnection>('GET', `${resource}/connection`, {
      ...options,
    });
  };

  /**
   * 夢なびの連携を解除
   * @method DELETE
   * @param options useFetchのオプション
   */
  const disconnectAccount = (options?: UseFetchOptions<void>) => {
    return useCustomFetch<void>('DELETE', `${resource}/connection`, {
      ...options,
    });
  };

  /**
   * 所持している夢なびポイントを取得
   * @method GET
   * @param options useFetchのオプション
   */
  const getPoints = (options?: UseFetchOptions<YumenaviPoint>) => {
    return useCustomFetch<YumenaviPoint>('GET', `${resource}/point`, {
      ...options,
    });
  };

  return {
    getIsConcurrentFlow,
    startConcurrentFlow,
    startPhoneNumberAuth,
    sendPhoneNumberAuthCode,
    creteMember,
    getConnection,
    disconnectAccount,
    getPoints,
  };
};
