import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import { YumenaviFlow, YumenaviConnection, YumenaviPoint } from '../api/types';

const getIsConcurrentFlow: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviFlow>({
      isYumenaviFlow: true,
    }),
  );
};

const startConcurrentFlow: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const startPhoneNumberAuth: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(
    ctx.status(204),

    // // すでに同じ電話番号に紐づけられた夢なびアカウントが存在する場合
    // ctx.status(422),
    // ctx.json({
    //   code: 502,
    //   message: 'すでに同じ電話番号に紐づけられた夢なびアカウントが存在します。',
    // }),
  );
};

const sendPhoneNumberAuthCode: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(ctx.status(204));
};

const creteMember: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(201));
};

const getConnection: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviConnection>({
      isConnected: true,
      isPhoneVerified: true,
    }),
  );
};

const disconnectAccount: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const getPoints: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json<YumenaviPoint>({
      yumenaviPoint: 1100,
    }),
  );
};

const mockYumenavi = {
  getIsConcurrentFlow,
  startConcurrentFlow,
  startPhoneNumberAuth,
  sendPhoneNumberAuthCode,
  creteMember,
  getConnection,
  disconnectAccount,
  getPoints,
};
export default mockYumenavi;
