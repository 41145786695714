import { useLoginState } from '@/composables/store/useLoginStore';
import { UserRepository } from '@/features/user/api/userRepository';
import { useCompareVersions } from '@/composables/useCompareVersions';

/** グローバルStateへ保持された値がなければ、Flutterから取得して保持するラッパー関数 */
const fetchAndSetStateWrapper = async <T>(
  state: Ref<T>,
  fetchFunc: () => Promise<T | undefined>,
  setFunc: (value: T) => void,
  defaultValue: T,
): Promise<void> => {
  if (!state.value) {
    const result = await fetchFunc();
    setFunc(result ?? defaultValue);
  }
};

export default defineNuxtRouteMiddleware(async (to, from) => {
  const repository = UserRepository();
  const {
    getUserId,
    getAuthToken,
    oldGetAuthToken,
    getCurrentVersion,
    createAccessKey,
    getIsTutorialViewed,
    getIsAgreed,
    getUseWithLogIn,
  } = useFlutterConnection();
  const {
    userId,
    authToken,
    accessKey,
    useWithLogIn,
    isTutorialViewed,
    isAgreed,
    currentVersion,
    setUserId,
    setUseWithLogIn,
    setAuthToken,
    setOldAuthToken,
    setAccessKey,
    setIsTutorialViewed,
    setIsAgreed,
    setCurrentVersion,
  } = useLoginState();

  await Promise.all([
    // API認証キー
    fetchAndSetStateWrapper(accessKey, createAccessKey, setAccessKey, ''),
    // チュートリアルを見たかどうか
    fetchAndSetStateWrapper(
      isTutorialViewed,
      getIsTutorialViewed,
      setIsTutorialViewed,
      false,
    ),
    // 利用規約同意の有無
    fetchAndSetStateWrapper(isAgreed, getIsAgreed, setIsAgreed, false),
    // ユーザーID
    fetchAndSetStateWrapper(userId, getUserId, setUserId, 0),
    // 現在のバージョン
    fetchAndSetStateWrapper(
      currentVersion,
      getCurrentVersion,
      setCurrentVersion,
      '',
    ),
  ]);

  // 認証に対応していないバージョンの場合は処理をスキップ
  const { isAppSupportCognitoAuth } = useCompareVersions();
  const authSupported = isAppSupportCognitoAuth();

  // アクセストークン
  if (authSupported) {
    await fetchAndSetStateWrapper(authToken, getAuthToken, setAuthToken, '');
  } else {
    await fetchAndSetStateWrapper(
      authToken,
      oldGetAuthToken,
      setOldAuthToken,
      '',
    );
  }

  if (!accessKey.value)
    throw createError(
      'アクセスキーが存在しないためアプリを起動できません。アンインストールした後、再度インストールしてください。',
    );

  if (!userId.value || !authToken.value) {
    // 新規ユーザーデータを生成
    const { data } = await repository.create();
    if (!data.value) throw new Error('ユーザーの情報の生成に失敗しました。');

    if (authSupported) {
      await Promise.all([
        setUserId(data.value.user.id),
        setAuthToken(data.value.user.token),
      ]);
    } else {
      await Promise.all([
        setUserId(data.value.user.id),
        setOldAuthToken(data.value.user.token),
      ]);
    }
    if (authSupported) await setUseWithLogIn(true); // ログイン画面を出す
  } else {
    // 認証機能に対応しているかバージョンガード
    if (useWithLogIn.value != undefined || !authSupported) return;
    const _useWithLogIn = await getUseWithLogIn();
    // NOTE: ログイン機能導入前にすでに利用していたユーザーはそのままTOPへ遷移させる
    if (_useWithLogIn == undefined) await setUseWithLogIn(!!_useWithLogIn); // ログイン画面を出さない
  }

  // NOTE: ローカルで初回起動動作を確認したい場合はコメントアウトする
  const __DEBUG__ = useRuntimeConfig().public.env === 'local';
  if (__DEBUG__) return;

  // NOTE: 以下、チュートリアル/利用規約画面への遷移時は除外
  if (to.path === '/agreement' || to.path === '/tutorial') return;

  // チュートリアルを見終わっていない場合はチュートリアル画面へ遷移
  if (!isTutorialViewed.value) return navigateTo('/tutorial');
  // 未同意の場合は利用規約画面へ遷移
  if (!isAgreed.value) return navigateTo('/agreement');
});
