import { default as about_45passYvpkEY5BAKMeta } from "/codebuild/output/src2536335442/src/src/pages/about-pass.vue?macro=true";
import { default as agreementLDYYUDdxF2Meta } from "/codebuild/output/src2536335442/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliasZKLdb1l0GBMeta } from "/codebuild/output/src2536335442/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmj2z5ocMFo5Meta } from "/codebuild/output/src2536335442/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasvFypgKyX3iMeta } from "/codebuild/output/src2536335442/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editTSKveezHbDMeta } from "/codebuild/output/src2536335442/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexNp7oTgr0WlMeta } from "/codebuild/output/src2536335442/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmZxZcY3fItPMeta } from "/codebuild/output/src2536335442/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexfHbNGpjveXMeta } from "/codebuild/output/src2536335442/src/src/pages/card/create/index.vue?macro=true";
import { default as indexkjn2ukqZhHMeta } from "/codebuild/output/src2536335442/src/src/pages/card/index.vue?macro=true";
import { default as completeMTyk7PRmt4Meta } from "/codebuild/output/src2536335442/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmUmokc7gbsjMeta } from "/codebuild/output/src2536335442/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as register16EbK4fN0VMeta } from "/codebuild/output/src2536335442/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactJijSc0AAPZMeta } from "/codebuild/output/src2536335442/src/src/pages/contact.vue?macro=true";
import { default as _91id_93PKqWV7QAwzMeta } from "/codebuild/output/src2536335442/src/src/pages/coupon/[id].vue?macro=true";
import { default as index8AGkK7RGebMeta } from "/codebuild/output/src2536335442/src/src/pages/coupon/index.vue?macro=true";
import { default as faqmyghP4v8WUMeta } from "/codebuild/output/src2536335442/src/src/pages/faq.vue?macro=true";
import { default as index7rUILcTkwVMeta } from "/codebuild/output/src2536335442/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93o3zZaQ64aKMeta } from "/codebuild/output/src2536335442/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexTRc8lfrpJUMeta } from "/codebuild/output/src2536335442/src/src/pages/index.vue?macro=true";
import { default as maintenanceyyOlTIigKBMeta } from "/codebuild/output/src2536335442/src/src/pages/maintenance.vue?macro=true";
import { default as completeBM8J83gYR7Meta } from "/codebuild/output/src2536335442/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmvVhZ4B7mFTMeta } from "/codebuild/output/src2536335442/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerNE1D6v9UfUMeta } from "/codebuild/output/src2536335442/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codejnmFiOhv5IMeta } from "/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmCaYJ0K84eYMeta } from "/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registeruGh3XJmUHaMeta } from "/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuDli7oxGubLMeta } from "/codebuild/output/src2536335442/src/src/pages/menu.vue?macro=true";
import { default as indexviBxfT1JcVMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/index.vue?macro=true";
import { default as indexByYhWibMqwMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sampleLkOky8CjMkMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completeNUJvOGVmH5Meta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeZC2vqDx80lMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexFdChiRDiZHMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as index8UMazTsbOcMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeZNWlbBAuptMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmswk0xc7qvdMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexUmn43yLpjdMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updateRd3g5ZYdKHMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmkvfq2BdFkSMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexpmYzFfQhrvMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updater0ZUBV3w7ZMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeltYbuUJ86RMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatemLeq4VMWjpMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexevFK1MDaV2Meta } from "/codebuild/output/src2536335442/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93V5rgiOXP3hMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as complete3jElFQJDfMMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmCHcxWWGlTXMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeoPp9sbNdrHMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmlFp0IlbqQBMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentyMTbFKfFyoMeta } from "/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93bxoeXmOBy3Meta } from "/codebuild/output/src2536335442/src/src/pages/notice/[id].vue?macro=true";
import { default as index5MJKgGllF7Meta } from "/codebuild/output/src2536335442/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93CPOHkHpqUdMeta } from "/codebuild/output/src2536335442/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementMfqvZyhqKWMeta } from "/codebuild/output/src2536335442/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeQa2ExX51c7Meta } from "/codebuild/output/src2536335442/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codePeneg0QkjOMeta } from "/codebuild/output/src2536335442/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordYwbCtVl6RSMeta } from "/codebuild/output/src2536335442/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailz1cXaBB2xOMeta } from "/codebuild/output/src2536335442/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codemjB3f8u8brMeta } from "/codebuild/output/src2536335442/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as index63eNqDJGkPMeta } from "/codebuild/output/src2536335442/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codeMW7VGnEub4Meta } from "/codebuild/output/src2536335442/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as index6BxAiQWZVzMeta } from "/codebuild/output/src2536335442/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialCiPeFUoLWTMeta } from "/codebuild/output/src2536335442/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/about-pass.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasZKLdb1l0GBMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmj2z5ocMFo5Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasvFypgKyX3iMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editTSKveezHbDMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmZxZcY3fItPMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexfHbNGpjveXMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeMTyk7PRmt4Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmUmokc7gbsjMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: register16EbK4fN0VMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: index8AGkK7RGebMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: index7rUILcTkwVMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexTRc8lfrpJUMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeBM8J83gYR7Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmvVhZ4B7mFTMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerNE1D6v9UfUMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codejnmFiOhv5IMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmCaYJ0K84eYMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registeruGh3XJmUHaMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuDli7oxGubLMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexviBxfT1JcVMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexByYhWibMqwMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampleLkOky8CjMkMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexevFK1MDaV2Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: complete3jElFQJDfMMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmCHcxWWGlTXMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeoPp9sbNdrHMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmlFp0IlbqQBMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentyMTbFKfFyoMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: index5MJKgGllF7Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93CPOHkHpqUdMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeQa2ExX51c7Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codePeneg0QkjOMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordYwbCtVl6RSMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailz1cXaBB2xOMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codemjB3f8u8brMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: index63eNqDJGkPMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codeMW7VGnEub4Meta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index6BxAiQWZVzMeta || {},
    component: () => import("/codebuild/output/src2536335442/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2536335442/src/src/pages/tutorial.vue")
  }
]